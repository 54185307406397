import React, { useState, useEffect } from "react";
import "./footer.css";
import { fetchLogoData } from "../../data/LogoData"; // Import the fetchLogoData function

const Footer = () => {
  const [logoUrl, setLogoUrl] = useState(""); // State to hold the logo URL

  useEffect(() => {
    // Fetch logo data when the component mounts
    fetchLogoData().then(fetchedData => {
      if (fetchedData.logo && fetchedData.logo.length > 0) {
        setLogoUrl(`https://cms.kasmaethio.org/storage/${fetchedData.logo[0].image_path}`);
      } else {
        console.error("Logo data not found");
      }
    }).catch(error => {
      console.error("Error fetching logo data:", error);
    });
  }, []);

  return (
    <>
      <footer>
        <div className="container">
          <div className="logo-section">
            {logoUrl ? (
              <img 
                src={logoUrl} 
                alt="Logo" 
                className="footer-logo"
              />
            ) : (
              <p>Logo not available</p>
            )}
            <h2>Do You Need Help With Anything?</h2>
            <a href="/contact" className="contact-button">Contact Us</a>
          </div>

          <div className="contact-section">
            <h3>Contact Us</h3>
            <p>አድራሻ፡ አዲስ አበባ ሳርቤት ስቴርስ ህንጽ 5ኛ ፎቅ</p>
            <p>ስልክ፡ +251 11111111</p>
            <p>ኢሜይል፡ info@kasma.com</p>
          </div>

          <div className="useful-links">
            <h3>Useful Links</h3>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About Us</a></li>
              <li><a href="/services">Services</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>

          <div className="newsletter">
            <h3>Latest News</h3>
            <p>"Discover our latest blog featuring the newest informations!"</p>
            <a href="/blog" className="contact-button">Blog</a>
          </div>
        </div>
      </footer>
      <div className="legal">
        <span>Copyright Skylink Technologies © 2024. All rights reserved</span>
      </div>
    </>
  );
};

export default Footer;