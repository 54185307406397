import React from "react";
import "./CompanyStatement.css"; // Ensure this matches your CSS filename

const CompanyStatement = () => {
  return (
    <div className="company-statement">
      <div className="content">
        <div className="statement">
          <h2>ካስማ ኢስላማዊ ድርጅት</h2>
            <h1>Kasma Islamic Organization</h1>

          <p>
          ካስማ ኢስላማዊ ድርጅት ከመረጃ ምንጭነት ወደ ፕሮጀክት ፈጻሚነት የሚያሸጋግረውን በ 04/09/2023 ከፌደራል መጅሊስ በሰርተፊኬት ቁጥር 200/010/15 በኢስላማዊ ድርጅትነት ተመዝገቦ እየተንቀሳቀሰ የሚገኝ ቦርድ መር ድርጅት ነው፡፡ 
ካስማ በገጠሩ የሀገራችን ክፍል የሚገኙ ዛውያዎች እና ሀሪማዎች በኢስላማዊ ትምህርት ዘርፍ ከተለያዩ አቅጣጫዎች እና አገልግሎቶች አንጻር ተመራጭ የእውቀት መዳራሻ እንዲሆኑ አልሞ እየተንቀሳቀሰ  ይገኛል፡፡

          </p>
          <a href="services" className="approach-button">Our Approach</a>
        </div>
      </div>
      <div className="vision-mission-values">
        <div className="item vision">
          <div className="icon">🌍</div>
          <div>
            <h3>ራዕይ\ Vission</h3>
            <p>
            
ሀሪማዎች ኢስላማዊ እውቀትን ከትውልድ ወደ ትውልድ የማስተላለፍ ሚናቸውን በአግባቡ የሚወጡ ተመራጭ የዒልም ማእከላት ሲሆኑ ማየት

            </p>
          </div>
        </div>
        <div className="item mission">
          <div className="icon">📋</div>
          <div>
            <h3>ተልዕኮ\ Missions</h3>
            <p>
            
የመሻይኾችን የኢኮኖሚ ችግር በመቅረፍ፣ የደረሶችን መስረታዊ አስፈልጎትና የትምሀርት ቁሳቁስ በሟሟላት እንዲሁም የሀሪማዎችን የመብራት፣ የውሀና ሊሎች የመሰረተ ልማት አገልግሎቶችን በመገምባት ሀሪማዎች ምቹና ተመራጭ የዒልም ማእከላት እንዲሆኑ ማስቻል፡፡ 

            </p>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default CompanyStatement;