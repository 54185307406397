import React, { useState, useEffect } from "react";
import "./header.css";
import { nav } from "../../data/Data"; // Keep this import for nav data
import { fetchLogoData } from "../../data/LogoData"; // Import fetchLogoData
import { Link } from "react-router-dom";

const Header = () => {
  const [navList, setNavList] = useState(false);
  const [logoUrl, setLogoUrl] = useState(""); // State to hold the logo URL
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    // Fetch logo data when the component mounts
    fetchLogoData()
      .then((fetchedData) => {
        if (fetchedData.logo && fetchedData.logo.length > 0) {
          setLogoUrl(
            `https://cms.kasmaethio.org/storage/${fetchedData.logo[0].image_path}`
          );
        } else {
          setError("No logo found");
        }
      })
      .catch((error) => setError("Failed to load logo"));
  }, []);

  return (
    <header>
      <div className='container flex'>
        <div className='logo-container'>
          {logoUrl ? (
            <Link to='/'>
              <img src={logoUrl} alt='Logo' className='logo' />
            </Link>
          ) : (
            <p>{error || "Loading logo..."}</p>
          )}
        </div>
        <div className='nav'>
          <ul className={navList ? "small" : "flex"}>
            {nav.map((list, index) => (
              <li key={index}>
                <Link to={list.path}>{list.text}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className='toggle'>
          <button onClick={() => setNavList(!navList)}>
            {navList ? <i className='fa fa-times'></i> : <i className='fa fa-bars'></i>}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
