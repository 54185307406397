import axios from 'axios';

export const fetchAboutPageData = async () => {
  try {
    const response = await axios.get('https://cms.kasmaethio.org/api/aboutpage');
    return response.data;
  } catch (error) {
    console.error('Error fetching About page data:', error);
    throw error;
  }
};
