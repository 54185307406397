// src/data/homeimage.js

export const homeimage = async () => {
    try {
      const response = await fetch('https://cms.kasmaethio.org/api/carousel-images');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return {};
    }
  };
  