import axios from "axios";

// src/data/NewInfoData.js
export const fetchNewInfoData = async () => {
  try {
    const response = await fetch("https://cms.kasmaethio.org/api/new_infos"); // Ensure this URL is correct
    const data = await response.json();
    
    if (data.success) {
      return data.data; // Return the 'data' field from the response
    } else {
      throw new Error("Failed to fetch New Info");
    }
  } catch (error) {
    console.error("Error fetching New Info:", error);
    return { description: "", image_path: "", title: "" };
  }
};
