import React, { useState, useEffect } from "react";
import axios from "axios"; // Import axios for API calls

const FeaturedCard = () => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    // Fetch the features data from the Laravel API
    axios.get("https://cms.kasmaethio.org/api/features")
      .then(response => {
        setFeatures(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the features!", error);
      });
  }, []);

  return (
    <>
      <div className='content grid5 mtop'>
        {features.map((item, index) => (
          <div className='box' key={index}>
            <img src={`https://cms.kasmaethio.org/storage/${item.image_path}`} alt='' />
            <h4>{item.description}</h4>
          </div>
        ))}
      </div>
    </>
  )
}

export default FeaturedCard;
