import React, { useState, useEffect, useRef } from "react";
import axios from "axios"; // Import axios for API calls
import './services.css'; // Ensure you have the right CSS file

const Servicescard = () => {
  const [services, setService] = useState([]);
  const scrollRef = useRef(null); // Reference for the scroll container

  useEffect(() => {
    // Fetch the features data from the Laravel API
    axios.get("https://cms.kasmaethio.org/api/services")
      .then(response => {
        setService(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the services!", error);
      });
  }, []);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300; // Adjust scroll amount as needed
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="services-container">
      <h2>Our Services</h2>
      <p>We are working to achieve this strategic focus area</p>
      <div className="scroll-buttons">
        <button onClick={() => scroll('left')} className="scroll-button">←</button>
        <div className='content' ref={scrollRef}>
          {services.map((item, index) => (
            <div className='box' key={index}>
              <img src={`https://cms.kasmaethio.org/storage/${item.image_path}`} alt={item.description} />
              <h4>{item.description}</h4>
            </div>
          ))}
        </div>
        <button onClick={() => scroll('right')} className="scroll-button">→</button>
      </div>
    </div>
  );
};

export default Servicescard;