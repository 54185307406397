import React, { useState, useEffect } from "react";
import Back from "../common/Back";
import { fetchAboutPageData } from "../data/AboutPage";
import "./about.css";
import Heading from "../common/Heading";

const About = () => {
  const [data, setData] = useState({
    aboutpage: { description: "", image_path: "", title: "" },
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const fetchedData = await fetchAboutPageData();
        setData({ aboutpage: fetchedData });
      } catch (err) {
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <section className='about'>
      <Back
        className='title'
        title={`About Us - ${data.aboutpage.title || 'Who We Are?'}`} 
        cover={`https://cms.kasmaethio.org/storage/${data.aboutpage.image_path}`} 
      />
      <div className='container'>
        <div className='left'>
          <div className='description'>
            <Heading title={data.aboutpage.title || 'Our building'} />
            {data.aboutpage.description ? (
              data.aboutpage.description.split("\n").map((text, index) => (
                <p key={index}>{text}</p>
              ))
            ) : (
              <p>No description available.</p>
            )}
          </div>
        </div>
        <div className='right'>
          {data.aboutpage.image_path ? (
            <img 
              src={`https://cms.kasmaethio.org/storage/${data.aboutpage.image_path}`} 
              alt='About Us' 
              className='about-image'
            />
          ) : (
            <p>No image available.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default About;
