import React, { useState } from "react";
import axios from "axios";
import Heading from "../common/Heading";
import "./zekah.css";
import image from "./image.webp";

const Zekah = () => {
  const [formData, setFormData] = useState({
    bank_name: "",
    transaction_id: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://cms.kasmaethio.org/api/zekah-collections",
        formData
      );
      alert("Your Zekah collection has been submitted successfully!");
      // Clear the form
      setFormData({
        bank_name: "",
        transaction_id: "",
      });
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      alert("Error submitting form. Please try again.");
    }
  };

  return (
    <div className="zekah-container">
      <div className="zekah-left">
        <img
          src={image} // Replace with your actual static image path
          alt="Facing Challenges Together"
          className="zekah-image"
        />
        <Heading title="Zekah collection Form" />
        <p className="form-description">
          After Your contribute, Please Fill Out This Form.
        </p>
        <form onSubmit={handleSubmit} className="support-form">
          <label>
            Bank Name
            <input
              type="text"
              name="bank_name"
              placeholder="Bank Name"
              value={formData.bank_name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Bank Transaction ID
            <input
              type="text"
              name="transaction_id"
              placeholder="Bank Transaction ID"
              value={formData.transaction_id}
              onChange={handleChange}
              required
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
      <div className="zekah-right">
        <Heading title="KASMA ZEKAH BANK ACCOUNTS." />
        <ul className="bank-accounts">
          <li>ዘምዘም ባንክ፡0028708210301</li>
          <li>ሂጅራ ባንክ፡1000013130002</li>
          <li>ንግድ ባንክ፡1000471030841</li>
        
        </ul>
        <button
          onClick={() => (window.location.href = "/")}
          className="back-home-button"
        >
          Back Home
        </button>
      </div>
    </div>
  );
};

export default Zekah;
