import React, { useState, useEffect } from "react";
import Back from "../common/Back";
import { fetchNewInfoData } from "../data/NewInfoData";
import "./newinfo.css";
import Heading from "../common/Heading";

const NewInfo = () => {
  const [data, setData] = useState({
    newinfo: { description: "", image: "", title: "" },
  });

  useEffect(() => {
    fetchNewInfoData().then(fetchedData => {
      console.log("Fetched Data:", fetchedData); // Debugging line
      setData({ newinfo: fetchedData });
    });
  }, []);

  return (
    <>
      <section className='newinfo'>
        <div>
          <Back
            className='title'
            title={`New Info - ${data.newinfo.title || 'Latest Info'}`}
            cover={`https://cms.kasmaethio.org/storage/${data.newinfo.image || 'default.jpg'}`} // Ensure a default cover image is provided
          />
        </div>
        <div className='container'>
          <div className='left'>
            <div className='description'>
              <Heading title={data.newinfo.title || 'New Info'} />
              {data.newinfo.description ? (
                data.newinfo.description.split("\n").map((text, index) => (
                  <p key={index}>{text}</p>
                ))
              ) : (
                <p>No description available.</p>
              )}
            </div>
          </div>
          <div className='right'>
            {data.newinfo.image ? (
              <img
                src={`https://cms.kasmaethio.org/storage/${data.newinfo.image}`}
                alt='New Info'
                className='newinfo-image'
              />
            ) : (
              <p>No image available.</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default NewInfo;
