import React from "react"
import CompanyStatement from "./CompanyStatement/CompanyStatement"
import Featured from "./featured/Featured"
import Hero from "./hero/Hero"
import Services from "./services/Services"




const Home = () => {
  return (
    <>
      <Hero />
      <Services/>
      <CompanyStatement />
      <Featured />
     
      
    </>
  )
}

export default Home
