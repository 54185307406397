import React, { useState, useEffect } from "react";
import Back from "../common/Back";
import "../home/services/services.css";
import Servicescard from "../home/services/Servicescard";
import { fetchAboutPageData } from "../data/AboutPage";

const Services = () => {
  const [coverImage, setCoverImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadAboutPageData = async () => {
      try {
        const fetchedData = await fetchAboutPageData();
        setCoverImage(fetchedData.image_path);
      } catch (err) {
        setError("Failed to load image.");
      } finally {
        setLoading(false);
      }
    };

    loadAboutPageData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <section className='services mb'>
        <div className="servicess mb">
          <Back
            title="Services - All Services"  // Keep the original title
            cover={`https://cms.kasmaethio.org/storage/${coverImage}`}  // Use the image from the About page
          />
        </div>

        <div className='featured container'>
          <Servicescard />
        </div>
      </section>
    </>
  );
};

export default Services;
