import React from "react";
import Heading from "../../common/Heading";
import "./services.css";
import ServicesCard from "./Servicescard";

const Services = () => {
  return (
    <>
      <section className='services background'>
        <div className='container'>
          <Heading title='Services we provide in kasma' subtitle='Here are all service we contributed.' />
          <ServicesCard />
        </div>
      </section>
    </>
  )
}

export default Services;
