const fetchLogoData = async () => {
    try {
      const response = await fetch("https://cms.kasmaethio.org/api/logo");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const logoData = await response.json();
      return { logo: logoData };
    } catch (error) {
      console.error("Failed to fetch logo data:", error);
      return { logo: [] };
    }
  };
  
  export { fetchLogoData };
  