


// Static data
export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "services",
    path: "/services",
  },
  {
    text: "zekah",
    path: "/zekah",
  },
  {
    text: "blog",
    path: "/newinfo",
  },
  {
    text: "contact",
    path: "/contact",
  },
];






